<template>
    <div>
        <div class="policybox">
            <h1>Política de privacidad</h1>

            <br>Bienvenido a la aplicación MAXPréstamo Plus, el desarrollador de " MAXPréstamo Plus" es Digital Moneyboxmx, SA de CV, SOFOM, ENR (en adelante nosotros), damos gran importancia a su privacidad y seguridad de datos, por eso hemos desarrollado esta privacidad política para explicar cómo recopilamos, utilizamos, almacenamos y protegemos su información personal. Lea atentamente esta política antes de utilizar la aplicación MAXPréstamo Plus. Una vez que utilice nuestros servicios, acepta todos los términos de esta política de privacidad.

            <h3>1. Recopilación de información</h3>
            <br>su uso de la aplicación MAXPréstamo Plus, recopilaremos la siguiente información personal:
            <br><strong>Información Personal</strong>
            <br>Usar:
            <br> * Incluyendo su nombre, fecha de nacimiento, sexo, información de tarjeta de identificación, información de trabajo,etc., para identificarlo y evitar fraudes.

            <br> <strong>Información de ubicación geográfica</strong>
            <br>Usar:
            <br> * Cuando un usuario utiliza MAXPréstamo Plus para pedir dinero prestado, la APP necesita acceder a la información de ubicación aproximada del usuario. Mejore la calidad del servicio y combine de manera efectiva el servicio al cliente adecuado según la ubicación del usuario.

            <br><strong>Información Financiera</strong>
            <br>Usar:
            <br>* Como información de la cuenta bancaria para evaluar su puntaje crediticio y procesar solicitudes relacionadas.

            <br><strong>Información de la lista de contactos</strong>
            <br>Usar:
            <br>*  Cuando un usuario utiliza la función de préstamo de MAXPréstamo Plus, con el consentimiento explícito del usuario, le solicitamos que seleccione información de contacto (incluido el nombre del contacto, información de contacto, relación, etc.) de la lista de contactos y la cargue como contacto de emergencia para Préstamo del usuario, calcule el valor del crédito del usuario según el modelo de control de riesgos.
            <br>* No nos comunicaremos con el contacto de emergencia que usted ingresó si se puede contactar al usuario.MAXPréstamo Plus no puede obtener la información de su lista de contactos, tenga la seguridad.

            <br>*  La información recopilada será encriptada y cargada en https://oundfort.honestybestpolicy.com/proxyuploader/. Cabe señalar que MAXPréstamo Plus solo puede obtener la información de contacto que usted cargue activamente.

            <br><strong>Información del dispositivo</strong>
            <br>Usar:
            <br>*Como modelo de dispositivo, versión del sistema operativo, etc. para garantizar que la aplicación se ejecute sin problemas en su dispositivo

            <br><strong>Permisos de la cámara</strong>
            <br>Usar:
            <br>* Acceda a la cámara de su dispositivo móvil para poder cargar fácilmente los documentos y fotografías necesarios, acelerando el procesamiento de la solicitud de préstamo.
            <br>* Cargue información de identidad para cotejar y verificar la autenticidad del usuario para evitar fraudes.

            <br><strong>Información de la imagen</strong>
            <br>Usar:
            <br>* Para garantizar que nuestro servicio funcione sin problemas, necesitamos recopilar y almacenar información de imágenes cuando carga documentos visuales (como referencias de crédito o capturas de pantalla).
            <br>* Solo recopilaremos información que usted confirme cargar con su consentimiento explícito y nos aseguraremos de que se implementen estrictos estándares de seguridad de datos para proteger su información personal. Tenga en cuenta que MAXPréstamo Plus no puede acceder a su álbum de fotos. MAXPréstamo Plus respeta y protege la seguridad de sus datos.

            <br>* Todos los datos de las imágenes seleccionadas se cargarán a través de una conexión segura y se cifrarán en https://oundfort.honestybestpolicy.com/proxyuploader/ y nunca se divulgarán.

            <br><strong>Lista de aplicaciones instaladas (lista de aplicaciones instaladas y uso)</strong>
            <br>Usar:
            <br>* MAXPréstamo Plus necesita obtener información sobre sus aplicaciones instaladas (incluido el nombre de la aplicación, tiempo de instalación, etc.) para analizar sus preferencias personales y calcular su valor de crédito. MAXPréstamo Plus cargará esta información a través de un enlace seguro y de forma cifrada a https. ://oundfort.potentialclientstolearn.com/ nunca será divulgado.

            <br><strong>Propósito de la información del SMS</strong>
            <br>Usar:
            <br>* MAXPréstamo Plus solicitará acceso a la información de su mensaje de texto (incluido: título del mensaje de texto, contenido del mensaje de texto, si ha sido leído) cuando utilice servicios de préstamo, principalmente para verificación de identidad y seguridad de la cuenta para evitar fraudes y riesgos. MAXPréstamo Plus clasificará los mensajes de texto del usuario y cifrará los mensajes de texto necesarios para el control de riesgos y los transmitirá a https://oundfort.pottialclientstoearn.com/ para su almacenamiento y análisis de datos. MAXPréstamo Plus sigue estrictamente las políticas y requisitos de protección de datos para proteger los datos de los usuarios.
            <br>* MAXPréstamo Plus solo obtendrá datos relevantes durante el uso de la APLICACIÓN por parte del usuario después de que el usuario acepte explícitamente la autorización. El usuario puede cambiar la autorización en cualquier momento en la configuración de la aplicación.


            <br><strong>Información GAID</strong>
            <br>Usar:
            <br>* Analizar sus preferencias y comportamiento para brindarle una experiencia de servicio más personalizada.


            <br> <strong>Permisos de calendario</strong>
            <br> Usar:
            <br> * Al utilizar préstamos MAXPréstamo Plus, necesitamos acceso a los permisos de su calendario (incluidos: eventos, fechas, estado de eventos, etc.).
            <br> * Para configurar un recordatorio de pago para usted, asegúrese de no perder fechas de pago importantes y evitar retrasos debido a negligencia. Además, analizar la información relevante en el calendario nos ayudará a comprender mejor su cronograma, optimizando así nuestra evaluación dinámica de riesgos y brindando servicios financieros más precisos y personalizados.
            <br> * Cifraremos la información recopilada y la subiremos a https://oundfort.honestybestpolicy.com/proxyuploader/ y nunca la compartiremos con terceros. MAXPréstamo Plus solo obtendrá datos del calendario con tu autorización explícita, y podrás cancelar la autorización en cualquier momento en la configuración.

            <br> <strong>Social Accounts</strong>
            <br> Usar:
            <br> * MAXPréstamo Plus needs to collect the user's social account (mobile phone number, email, etc.) during the user's loan process to verify the user's identity and contact the user
            <br> * The collected information will be encrypted and stored in https://oundfort.honestybestpolicy.com/proxyuploader/ and will never be shared with third parties.

            <h3>2. Uso de la información</h3>
            <br>La información que recopilamos se utilizará para los siguientes fines:
            <br>* Proporcionar y mejorar servicios: procesar solicitudes de préstamos, proporcionar servicios de préstamos y mejorar la experiencia del usuario.
            <br>* Verificación de identidad: garantice la seguridad de los servicios de préstamo verificando la identidad y el estado financiero.
            <br>* Comunicación: enviarle notificaciones de servicio, actualizaciones y promociones para asegurarse de que conozca las últimas funciones y ofertas de la aplicación.
            <br>* Seguridad y prevención: se utiliza para detectar y prevenir posibles fraudes, abusos o violaciones, proteger la seguridad de los datos del usuario y mantener el funcionamiento normal de la plataforma.

            <h3>3. Intercambio de información</h3>
            <br>No venderemos ni alquilaremos su información personal a terceros, pero podemos compartir su información en las siguientes circunstancias:
            <br>* Requisitos legales: De conformidad con los requisitos de leyes, reglamentos u órdenes judiciales, o en respuesta a solicitudes legales de agencias gubernamentales.
            <br>* Socios comerciales: comparta con socios comerciales confiables para ayudarnos a mejorar la calidad del servicio y completar las acciones requeridas.
            <br>* Prevención del fraude: la información necesaria puede compartirse con agencias antifraude para prevenir el fraude.

            <h3>4. Almacenamiento y protección de la información</h3>
            <br>Tomamos medidas técnicas y administrativas razonables para proteger su información personal contra acceso no autorizado, divulgación, alteración o destrucción. Las medidas de seguridad incluyen, entre otras:
            <br>* Utilice la tecnología de cifrado SSL para transmitir datos y garantizar la seguridad de los datos.
            <br>* Restringir el acceso a los datos del usuario únicamente al personal autorizado
            <br>* Realizar revisiones periódicas de seguridad de las prácticas de recopilación, almacenamiento y procesamiento de información para proteger la seguridad de los datos.

            <h3>5. Plazo de conservación de la información</h3>
            <br>Conservaremos su información personal durante el tiempo que sea razonablemente necesario para cumplir con los fines descritos en esta política de privacidad, según lo exige la ley aplicable.

            <h3>6. Derechos del usuario</h3>
            <br>" MAXPréstamo Plus", los usuarios tienen derecho a decidir conservar o eliminar su información personal. Respetamos y cooperamos con las solicitudes de eliminación de los usuarios. Los siguientes son los procedimientos de eliminación de datos que proporcionamos:
            <br>* Solicitud de eliminación en la aplicación
            <br>Los usuarios pueden enviar una solicitud para eliminar información de la cuenta a través de la configuración o las opciones de soporte dentro de la aplicación " MAXPréstamo Plus". Si lo solicita, eliminaremos todos los datos personales asociados con su cuent.
            <br>* Contacto por correo electrónico
            <br>Los usuarios también pueden solicitar la eliminación de datos enviando un correo electrónico a nuestra dirección de correo electrónico de atención al cliente (customer@digitalmoneyboxmx.com). Nuestro equipo de atención al cliente le ayudará con el proceso de eliminación de datos.
            <br>Cosas a tener en cuenta
            <br>No recuperable: tenga en cuenta que una vez que se elimina la información de su cuenta, todos los datos relacionados no se pueden recuperar. Si desea volver a utilizar el servicio " MAXPréstamo Plus" , debe registrar una nueva cuenta.

            <h3>7. Cambios a la Política de Privacidad</h3>
            <br>MAXPréstamo Plus App se reserva el derecho de actualizar o modificar esta política de privacidad en cualquier momento. Las actualizaciones de la política se publicarán en la página de política de privacidad de la aplicación. Al continuar utilizando nuestros servicios, aceptas el contenido actualizado de la política.

            <h3>8. Contáctanos</h3>
            <br>Si tiene alguna pregunta sobre esta Política de Privacidad o necesita más información, contáctenos en:
            <br>Correo electrónico: customer@digitalmoneyboxmx.com
            <br>Horario Laboral: Lunes a Domingo 9:00-17:30
            <br>Dirección: Avenida Javier Barros Sierra 540, Piso 5, Santa Fe, Alcaldía Álvaro Obregón, Ciudad de México, CP 01210

        </div>
    </div>
</template>

<script>
//import {  } from ''
export default {
    name: 'componentName',
    props: [],
    components: {},
    data() {
        return {

        }
    },
    mounted() {

    },
    created() {

    },
    methods: {

    },
}

</script>
<style scoped lang='less'>
.policybox {
    padding: 4vw 10vw;
    font-size: .7rem;
}

h1 {
    text-align: center;
    font-size: 1.2rem;
    margin: 3vw 0;
}

h2 {
    font-size: .8rem;
    margin: 2vw 0 1vw;
}
</style>